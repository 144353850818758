const isNonZero = (a) => {
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== "0") return true;
  }
  return false;
};

const removeZero = (a) => {
  for (let i = 0; i < a.length; ++i) {
    if (
      a[i] === "경" ||
      a[i] === "조" ||
      a[i] === "억" ||
      a[i] === "만" ||
      a[i] === "원"
    ) {
      let start = ++i;
      let end = 0;
      while ("0" === a[start + end]) {
        end++;
      }
      a.splice(start, end);
    }
  }
};

const toKrw = (a) => {
  const b = a.toString().split("").reverse();
  if (b.length > 16 && isNonZero(b.slice(16, 20))) {
    b.splice(16, 0, "경");
  }
  if (b.length > 12 && isNonZero(b.slice(12, 16))) {
    b.splice(12, 0, "조");
  }
  if (b.length > 8 && isNonZero(b.slice(8, 12))) {
    b.splice(8, 0, "억");
  }
  if (b.length > 4 && isNonZero(b.slice(4, 8))) {
    b.splice(4, 0, "만");
  }
  b.splice(0, 0, "원");
  removeZero(b.reverse());
  return b.join("");
};

export default toKrw;
