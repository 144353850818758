import { useEffect, useState, useRef } from "react";
import toKrw from "./numToKrw";
import ResultCard from "./components/resultCard";

function App() {
  const realType = [
    {
      name: "금", //매년 1월 30일 기준, 그람당 가격
      bojosa: "을",
      imgPath: "img/real_gold.svg",
      creteria: "연도별 1월 기준",
      values: [
        {
          year: 2022,
          value: 6.9868, //(만원/g)
        },
        {
          year: 2021,
          value: 6.6554,
        },
        {
          year: 2020,
          value: 6.0661,
        },
        {
          year: 2019,
          value: 4.7257,
        },
        {
          year: 2018,
          value: 4.6161,
        },
        {
          year: 2017,
          value: 4.5025,
        },
        {
          year: 2016,
          value: 4.3499,
        },
        {
          year: 2015,
          value: 4.4495,
        },
        {
          year: 2014,
          value: 4.3749,
        },
        {
          year: 2013,
          value: 5.8218,
        },
        {
          year: 2012, // 53498
          value: 6.2721,
        },
        {
          year: 2011,
          value: 4.8188,
        },
        //https://goldprice.org/ko/gold-price-history.html
        {
          year: 2010, //35504 * 1.156.5 여기서부터는 환율계산 직접. 달러인데 만자리인건 KG단위일때 값
          value: 4.106, //결과값에 /1000 (g단위 변환) 거기에 /10000 (만원으로 변환)
        },
        {
          year: 2009, //29129 * 1368.5
          value: 3.9863,
        },
        {
          year: 2008, //28857 * 943.9
          value: 2.7238,
        },
        {
          year: 2007, //070114 // 20148 * 940.5
          value: 1.8949,
        },
        {
          year: 2006, //060112 // 17562 * 984.4
          value: 1.7288,
        },
        {
          year: 2005, //050120 // 13573 * 1033.4
          value: 1.4026,
        },
        {
          year: 2004, //040205 // 12845 * 1168
          value: 1.5002,
        },
        //https://kr.investing.com/currencies/usd-krw //이하 환율은 대충 1월껄로 계산
        {
          year: 2003, //030122 // 11526 * 1189
          value: 1.3704,
        },
        {
          year: 2002, //020108 // 8957 * 1326
          value: 1.0144,
        },
        {
          year: 2001, //010205 // 8534 * 1261
          value: 1.0761,
        },
        {
          year: 2000, //000131 // 9108 * 1128
          value: 1.0273,
        },
      ],
    },
    {
      name: "비트코인",
      bojosa: "을",
      imgPath: "img/real_bitcoin.svg",
      creteria: "연도별 1월 기준",
      values: [
        {
          year: 2022, //
          value: 5750, //업비트 1월 1일 대충 값
          // value: 4819, //업비트 2월 19일 값
          // value: 2750, //음수 테스트용
        },
        {
          year: 2021,
          value: 3200,
        },
        {
          year: 2020,
          value: 833,
        },
        {
          year: 2019,
          value: 424,
        },
        {
          year: 2018,
          value: 1900,
        },
        {
          year: 2017, //업비트에 자료 없으므로 비트맥스 달러 기준 // 947 * 1206.85
          value: 114,
        },
        {
          year: 2016, // 440 * 1175.94
          value: 51,
        },
        {
          year: 2015, // 1093.9 * 290
          value: 31,
        },
        {
          year: 2014, // 1051.5 * 800
          value: 84,
        },
        {
          year: 2013, // 1066.25 * 20
          value: 2.1,
        },
        {
          year: 2012, // 2월 1일 // 1158.5 * 5
          value: 0.5,
        },
      ],
    },
    {
      name: "서울 아파트",
      bojosa: "를",
      imgPath: "img/real_samsung.svg",
      //국가통계포털 아파트 규모별 매매 실거래 평균가격 - kosis.kr 중대형(85m²~135m²) 약 25평~40평
      creteria: "연도별 1월 기준 (중대형(85m²~135m²))",
      values: [
        {
          year: 2022, //엄밀하게는 2021년 11월이지만, 22년 1월 자료가 없으므로 그냥 이 값으로 대체
          value: 1620.4,
        },
        {
          year: 2021, //1월
          value: 1306.8,
        },
        {
          year: 2020,
          value: 810.6,
        },
        {
          year: 2019,
          value: 841.1,
        },
        {
          year: 2018,
          value: 846.7,
        },
        {
          year: 2017,
          value: 644,
        },
        {
          year: 2016,
          value: 605,
        },
        {
          year: 2015,
          value: 589.1,
        },
        {
          year: 2014,
          value: 619.3,
        },
        {
          year: 2013,
          value: 589.6,
        },
        {
          year: 2012,
          value: 567.5,
        },
        {
          year: 2011,
          value: 672.4,
        },
        {
          year: 2010,
          value: 759.6,
        },
        {
          year: 2009,
          value: 709.2,
        },
        {
          year: 2008,
          value: 598.1,
        },
        {
          year: 2007,
          value: 509.5,
        },
        {
          year: 2006,
          value: 630,
        },
      ],
    },
    {
      name: "테슬라 주식",
      bojosa: "을",
      imgPath: "img/real_tsla.svg",
      //TSLA 대충 1월 초 가격
      creteria: "연도별 1월 기준",
      values: [
        {
          year: 2022, // 1189 * 1026
          value: 122,
        },
        {
          year: 2021, // 1085.5 * 880
          value: 95.524,
        },
        {
          year: 2020, // 1155 * 102.2
          value: 11.78,
        },
        {
          year: 2019, // 1114 * 63.5
          value: 7,
        },
        {
          year: 2018, // 1067 * 63.3
          value: 6.7,
        },
        {
          year: 2017, // 1206.85 * 45.8
          value: 5.5,
        },
        {
          year: 2016, // 1175.94 * 42.2
          value: 4.9,
        },
        {
          year: 2015, // 1093.9 * 41.3
          value: 4.5,
        },
        {
          year: 2014, // 1051.5 * 29.9
          value: 3.14,
        },
        {
          year: 2013, // 1066.25 * 6.88
          value: 0.73,
        },
        {
          year: 2012, // 1158.5 * 5.38
          value: 0.62,
        },
        {
          year: 2011, // 1158.5 * 5.65
          value: 0.6,
        },
        {
          year: 2010, // 2010 7월 2일가(상장) //1158.5 * 3.84
          value: 0.44,
        },
      ],
    },
  ];

  const buyItems = [
    /* 하.. 일주일 전이랑 가격이 다른데??? 거의5~8만원씩 빠졌네? */
    // {
    //   name: "F22",
    //   value: 37000000, //만원단위
    //   unit: "대",
    // },
    {
      name: "F-35",
      subName: "",
      value: 10000000, //천억
      unit: "대",
      imgPath: "img/good_f35.png",
      //source:"https://pxhere.com/ko/photo/767566"
      buyUrl: "https://go.airforce.mil.kr:448/mbshome/mbs/irms/index.do",
      //공군으로 갈까.. 록히드마틴으로 갈까... 록히드마틴 가봐야 못삼
      //https://www.lockheedmartin.com/en-us/products/f-35.html
      //토이프로젝트니까 ^^ 사려면 공군먼저 가야지!
    },
    {
      name: "람보르기니",
      subName: "아벤타도르",
      value: 75000,
      unit: "대",
      imgPath: "img/good_lambo.png",
      //source:"https://pxhere.com/ko/photo/1272427"
      buyUrl: "https://auto.danawa.com/auto/?Work=model&Model=2619", //뭐.. 딱히 다른 링크가 없다..
    },
    {
      name: "우주여행",
      subName: "",
      value: 53000, //$450,000
      unit: "회",
      imgPath: "img/good_space.png",
      //source:"https://pxhere.com/ko/photo/1252976"
      buyUrl: "https://www.virgingalactic.com/sign-up", //이미지는 버진갤럭틱이 아니지만...
    },
    {
      name: "포르쉐",
      subName: "911",
      value: 14900,
      unit: "대",
      imgPath: "img/good_porche.png",
      buyUrl: "https://www.porsche.com/korea/ko/modelstart/all/?modelrange=911",
    },
    {
      name: "벤츠",
      subName: "E Class",
      value: 8000, //약 8천만원
      unit: "대",
      imgPath: "img/good_benz.png",
      buyUrl:
        "https://www.mercedes-benz.co.kr/passengercars/mercedes-benz-cars/models/e-class/saloon-w213-fl/explore.html",
    },
    {
      name: "롤렉스",
      subName: "서브마리너",
      value: 5214,
      unit: "개",
      imgPath: "img/good_rolex3.png",
      //source https://www.rolex.com/ko/watches/submariner/m126619lb-0003.html
      buyUrl: "https://www.rolex.com/ko/watches/submariner/m126619lb-0003.html",
    },
    {
      name: "샤넬",
      subName: "클래식 플립 백",
      value: 1100,
      unit: "개",
      imgPath: "img/샤넬가방.png",
      //샤넬은 CC0 이미지를 찾을 수 없다.
      buyUrl: "https://www.chanel.com/kr/fashion/classic/c/1x3x14x1/handbags/",
    },
    // { // 200만원대 중복
    //   name: "GTX 3080Ti",
    //   value: 220,
    //   unit: "개",
    //   imgPath: "img/gtx3080.png",
    // },
    {
      name: "갤럭시 폴드3",
      subName: "",
      value: 190,
      unit: "개",
      imgPath: "img/good_galxyf.png",
      buyUrl: "https://link.coupang.com/a/jZ2ez",
    },
    {
      name: "아이폰13",
      subName: "",
      value: 115,
      unit: "개",
      imgPath: "img/good_iphon13_.png",
      buyUrl: "https://link.coupang.com/a/jYxQn",
    },
    {
      name: "플레이스테이션5",
      subName: "",
      value: 100,
      unit: "대",
      imgPath: "img/good_play.png",
      buyUrl: "https://link.coupang.com/a/jYxVD",
    },
    {
      name: "갤럭시S22",
      subName: "",
      value: 84,
      unit: "개",
      imgPath: "img/good_galxys22.png",
      buyUrl: "https://link.coupang.com/a/jYxBS",
    },
    {
      name: "호텔 숙박권", //차라리 애플워치를 넣자.
      subName: "시그니엘",
      value: 50,
      unit: "회",
      imgPath: "img/good_hotel.png",
      buyUrl: "https://www.lottehotel.com/global/ko.html",
    },
    {
      name: "애플워치", //차라리 애플워치를 넣자.
      subName: "SE",
      value: 35,
      unit: "개",
      imgPath: "img/good_awatchse.png",
      buyUrl: "https://link.coupang.com/a/jZ2wW",
    },
    // {
    //   name: "닌텐도 스위치",
    //   value: 35,
    //   unit: "대",
    //   imgPath: "img/good_nintendo2.png",
    //   buyUrl: "https://link.coupang.com/a/jYx51",
    // },
    {
      name: "에어팟 프로",
      subName: "",
      value: 32,
      unit: "개",
      imgPath: "img/good_airpod.png",
      buyUrl: "https://link.coupang.com/a/jYyag",
    },
    {
      name: "갤럭시 워치4", //차라리 애플워치를 넣자.
      subName: "",
      value: 21,
      unit: "개",
      imgPath: "img/good_gwatch4.png",
      buyUrl: "https://link.coupang.com/a/jZ33c",
    },
    {
      name: "파인다이닝",
      subName: "2인",
      value: 18,
      unit: "회",
      imgPath: "img/good_finedi_.png",
      //https://pxhere.com/en/photo/743695
    },
    {
      name: "갤럭시 버즈 프로",
      subName: "",
      value: 13,
      unit: "개",
      imgPath: "img/good_budspro.png",
      buyUrl: "https://link.coupang.com/a/jYyh3",
    },
    {
      name: "치킨",
      subName: "후라이드",
      value: 1.8,
      unit: "마리",
      imgPath: "img/good_chicken2.png",
    },
    {
      name: "타꼬야끼",
      subName: "",
      value: 0.0333,
      unit: "개",
      imgPath: "img/good_tacoyaki_.png",
    },
  ];

  const params = new URLSearchParams(window.location.search);
  let paramsTime = params.get("time");
  let paramsMoney = params.get("money");
  let paramsReal = params.get("real");

  const [shareUrl, setShareUrl] = useState(
    `https://${window.location.hostname}/`
  );

  // console.log(shareUrl);

  const calculateQuery = (time, money, real) => {
    const url = `https://${window.location.hostname}/?time=${time}%26money=${money}%26real=${real}`;
    // setShareUrl(url);
    // navigator.clipboard.write(url).then(() => {
    //   console.log(url);
    // });
  };

  const textInput = useRef();

  const copy = () => {
    if (!navigator.clipboard) {
      const el = textInput.current;
      el.select();
      document.execCommand("copy");
    } else {
      navigator.clipboard
        .writeText(shareUrl)
        .then(function () {
          alert("URL이 복사되었습니다!"); // success
        })
        .catch(function () {
          alert("err"); // error
        });
    }

    gtag("event", "share", {
      event_platform: "copy_url_direct",
    });
  };

  const getShareText = () => {
    const realt = realType.filter((item) => item.name === real)[0];
    return `만일 ${time}년에 ${toKrw(Math.floor(money) * 10000)}으로 ${
      realt.name
    }${realt.bojosa} 샀다면?`; // 전달할 텍스트
  };

  const kkeolkkeolUrl = window.location.href;

  useEffect(() => {
    console.log("init kakao");
    initKakao(); //
  }, []);

  //자바스크립트키로 카카오 init
  const initKakao = () => {
    if (window.Kakao) {
      const kakao = window.Kakao;
      if (!kakao.isInitialized()) {
        // kakao.init(process.env.REACT_APP_KAKAO_TOKEN);
        kakao.init("4f6b5f004b70c1895c163e5e242d4a50");
      }
    }
  };

  const shareKakao = () => {
    window.Kakao.Link.sendDefault({
      objectType: "text",
      // text: "기본 템플릿으로 제공되는 텍스트 템플릿은 텍스트를 최대 200자까지 표시할 수 있습니다. 텍스트 템플릿은 텍스트 영역과 하나의 기본 버튼을 가집니다. 임의의 버튼을 설정할 수도 있습니다. 여러 장의 이미지, 프로필 정보 등 보다 확장된 형태의 카카오링크는 다른 템플릿을 이용해 보낼 수 있습니다.",
      text: getShareText(),
      link: {
        mobileWebUrl: kkeolkkeolUrl,
        webUrl: kkeolkkeolUrl,
      },
    });
    gtag("event", "share", {
      event_platform: "kakao",
    });
  };

  const shareTwitter = () => {
    window.open(
      "https://twitter.com/intent/tweet?text=" +
        getShareText() +
        "&url=" +
        shareUrl
    );

    gtag("event", "share", {
      event_platform: "twitter",
    });
  };

  const shareTelegram = () => {
    window.open(
      "https://telegram.me/share/url?url=" +
        shareUrl +
        "&text=" +
        getShareText()
    );

    gtag("event", "share", {
      event_platform: "telegram",
    });
  };

  const [isInit, setIsInit] = useState(false);
  const init = () => {
    if (!isInit) {
      try {
        paramsTime = parseInt(paramsTime);
      } catch (error) {
        paramsTime = 2012;
      }
      if (2006 > paramsTime) {
        paramsTime = 2006;
      }
      if (paramsTime > 2021) {
        paramsTime = 2020;
      }
      if (2006 > paramsTime) {
        paramsTime = 2006;
      }

      try {
        paramsMoney = parseInt(paramsMoney);
      } catch (error) {
        paramsMoney = 1000;
      }
      if (paramsMoney > 1000000) {
        paramsMoney = 1000000;
      }
      if (0 > paramsMoney) {
        paramsMoney = 1000;
      }

      // if (
      //   !realType
      //     .map((v) => v.name)
      //     .some((v) => v === realType[paramsReal].name)
      // ) {
      //   paramsReal = "";
      // }
      setIsInit(true);
      // calculateQuery(paramsTime, paramsMoney, realType[paramsReal].name);
    }
  };

  init();

  function gtag() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
  }

  const [time, setTime] = useState(paramsTime ? paramsTime : 2012);
  const [money, setMoney] = useState(paramsMoney ? paramsMoney : 1000);
  const [real, setReal] = useState(paramsReal ? realType[paramsReal].name : "");
  const [result, setResult] = useState({}); //{expected, count, unit}

  const resultView = useRef(null);
  const executeScroll = () => resultView.current.scrollIntoView();

  const getResult = ({ realItem, date, 매매액 }) => {
    //valid
    const 몇년전 = 2022 - date;
    let tmpDate = date;
    let tmpMoney = money;
    if (real === "서울 아파트" && 매매액 < 10000) {
      tmpMoney = 10000;
      setMoney("10000");
      alert(
        `${money}만원으로는 서울에 아파트를 장만하기 어렵습니다.\n최소 1억은 있어야죠! 구매금액을 1억으로 조정합니다`
      );
    }
    if (!realItem.values[몇년전]) {
      tmpDate = realItem.values[realItem.values.length - 1].year;
      setTime(tmpDate);
      alert(
        `${date}년 당시 ${realItem.name} 가치 값을 얻지 못했습니다.\n대신 ${
          realItem.values[realItem.values.length - 1].year
        } 년도의 값을 사용합니다.`
      );
    }
    return calculate({ realItem: realItem, date: tmpDate, 매매액: tmpMoney });
  };

  useEffect(() => {
    // setResult(getResult({ realItem: real, date: time, 매매액: money }));
    if (real) {
      getResult({
        realItem: realType.filter((item) => item.name === real)[0],
        date: time,
        매매액: money,
      });
      executeScroll({ behavior: "smooth", block: "start" });
      calculateQuery(time, money, real);
    }
  }, [real]);

  const calculate = ({ realItem, date, 매매액 }) => {
    // console.log("calculate", realItem, date, 매매액);
    const 몇년전 = 2022 - date;
    const 그때단가 = realItem.values[몇년전].value;
    const 현재단가 = realItem.values[0].value;
    const 그때살수있던갯수 = 매매액 / 그때단가;
    // console.log("몇년전", 몇년전);
    // console.log("그때단가", 그때단가);
    // console.log("현재단가", 현재단가);
    // console.log("그때살수있던갯수", 그때살수있던갯수);
    // const 그때가 = 그때단가 * 그때살수있던갯수;
    const 현재가 = 현재단가 * 그때살수있던갯수; //만원기준
    const earned = 현재가 - money; //만원기준

    const goods = buyItems.map((item) => ({
      name: item.name,
      subName: item.subName,
      value: item.value, //단가
      count: Math.floor(현재가 / item.value),
      unit: item.unit,
      imgPath: item.imgPath,
      buyUrl: item.buyUrl,
    }));

    // const goodsByEarned = buyItems.map((item) => ({
    //   name: item.name,
    //   value: item.value, //단가
    //   count: Math.floor(earned / item.value),
    //   unit: item.unit,
    //   subName: item.subName,
    //   imgPath: item.imgPath,
    //   buyUrl: item.buyUrl,
    // }));

    setResult({
      expected: 현재가,
      earned: earned,
      goods: goods,
      // goodsByEarned: goodsByEarned,
    });
    gtag("event", "calculate", {
      event_label: `${real} ${date}년 ${money}만원`,
      event_real: real,
      event_date: date,
      event_money: 매매액,
    });
  };

  const initResult = () => {
    setReal("");
    setResult({});
  };

  const openLink = (item) => {
    const url = item.buyUrl;
    if (!url) {
      return;
    }
    if (url.includes("coupang")) {
      alert(
        "이 항목은 쿠팡 파트너스 활동의 일환으로,\n일정 수수료를 받은 개발자는 타꼬야끼를 사먹을 수 있을 것입니다."
      );
    }
    window.open(url);
    gtag("event", "click_url", {
      event_item: item.name,
    });
  };

  return (
    <div className="flex-row h-auto w-screen justify-center items-center">
      {/* 제목 */}
      <div className="flex-row md:flex w-full xl:w-1/2 justify-center items-center text-center p-8">
        {/* <img src={"img/kkulkkul_re.svg"} className="pb-16 max-h-64" /> */}
        <img src={"img/main.png"} className="flex pb-16 max-h-64 mx-auto" />
        <img src={"img/ungung.svg"} className="flex max-h-64 mx-auto" />
      </div>
      {/* 입력 */}
      <div
        id="k_input"
        className="flex-row w-full justify-center items-center bg-black text-white 
          font-bold text-2xl p-6 text-center"
      >
        <div>그때 샀더라면 지금 얼마일까?</div>
      </div>
      <div className="flex-row p-8 bg-gray-100">
        {/* <div class="flex w-full justify-center items-center">
          <span class="font-bold text-5xl p-8">
            그때 샀더라면 지금 얼마일까?
          </span>
        </div> */}
        <div className="w-full flex-row md:flex justify-center items-center text-2xl">
          <div className="flex w-full justify-center items-center font-bold">
            <div
              className="flex-none border-transparent border-4 text-gray-600
             font-bold"
            >
              만일
            </div>
            <div className="flex w-full">
              <input
                className="appearance-none bg-transparent w-full border-b border-gray-600
                leading-tight focus:outline-none text-center text-4xl"
                type="number"
                value={time}
                placeholder="연도"
                aria-label="Year"
                onChange={(e) => {
                  initResult();
                  if (e.target.value.length > 6) {
                    e.target.value = e.target.value.slice(0, 6);
                  }
                  setTime(e.target.value);
                }}
                onFocus={(e) => {
                  initResult();
                  // e.target.select();
                }}
              />
            </div>
            <div className="flex-none border-transparent border-4 text-gray-600 rounded">
              년에
            </div>
          </div>
          <div
            className={`flex items-center mt-8 md:mt-0 font-bold ${
              money.length > 5 ? "text-2xl" : "text-4xl"
            }`}
          >
            <input
              className="appearance-none bg-transparent w-full border-b border-gray-600
                leading-tight focus:outline-none text-center"
              type="number"
              value={money}
              placeholder="1000"
              aria-label="money"
              onChange={(e) => {
                initResult();
                if (e.target.value.includes("-")) {
                  return;
                }
                if (e.target.value.length > 9) {
                  e.target.value = e.target.value.slice(0, 9);
                }
                setMoney(e.target.value);
              }}
              onFocus={(e) => {
                initResult();
                // e.target.select();
              }}
            />
            <span className="flex-shrink-0 border-transparent border-4 text-2xl text-gray-700 rounded">
              만원 어치의
            </span>
          </div>
        </div>
        <div className="mt-10">
          <ul className="grid grid-cols-1 gap-x-5 md:grid-cols-2">
            {realType.map((item, index) => {
              return (
                <li key={`select-${index}`} className="relative">
                  <input
                    className="sr-only peer"
                    type="radio"
                    value={item.name}
                    name="answer"
                    checked={real === item.name}
                    id={`answer-${index}`}
                    onChange={(e) => {
                      setReal(e.target.value);
                    }}
                  />
                  <label
                    htmlFor={`answer-${index}`}
                    className="flex p-2 mb-4
                    bg-white border border-gray-100 rounded-full cursor-pointer 
                      focus:outline-none hover:bg-gray-100 peer-checked:ring-gray-500 
                      peer-checked:ring-2 peer-checked:border-transparent text-2xl items-center "
                  >
                    <img
                      className={`ml-4 w-16 xl:w-96`}
                      src={item.imgPath}
                      alt={item.name}
                    />
                    <div className="flex-auto text-center">
                      {item.name}
                      {item.bojosa}
                    </div>
                  </label>
                </li>
              );
            })}
          </ul>
          <div className="flex w-full justify-center items-center">
            <span className="font-bold text-4xl mt-4">
              {" "}
              <a href="#result">샀더라면?</a>
            </span>
          </div>
        </div>
      </div>
      {/* 계산결과 */}
      {real && (
        <div ref={resultView} className="text-4xl">
          <div
            className="flex-row w-full justify-center items-center bg-black text-white 
          font-bold p-8 text-center"
          >
            계산결과
          </div>
          <div
            className="flex-row w-full justify-center items-center
            p-4 bg-black text-white -mt-1"
          >
            <div className="flex w-full bg-black justify-center items-center text-gray-600 text-sm -mt-1">
              {" "}
              (* {time}년 1월 중 매수했다면)
            </div>
            {result?.goods && result.earned >= 0 ? (
              // 양수일 때 == 돈을 벌었을 때
              <div className="grid grid-cols-1 text-lg h-full">
                <div className="flex-row justify-center items-center text-center">
                  <div>
                    원금{"  "}
                    <span className="font-bold">
                      {toKrw(Math.floor(money) * 10000)}
                    </span>
                    {" + "}
                    <span className="font-bold">
                      {toKrw(Math.floor(result.earned) * 10000)}
                    </span>
                    {"  "} 합쳐
                  </div>
                </div>
                <div className="flex justify-center items-center mt-4">
                  총
                  <span className="font-bold text-3xl">
                    {" "}
                    {toKrw(Math.floor(result.expected) * 10000)}
                  </span>
                  이 되었다!
                  <br />
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-1 h-full text-5xl">
                <div className="flex justify-center items-center">
                  원금{"  "}
                  <span className="font-bold">
                    {toKrw(Math.floor(money) * 10000)}{" "}
                    {toKrw(Math.floor(result.earned) * 10000)} 빼서
                  </span>
                </div>
                <div className="flex justify-center items-center ">
                  총
                  <span className="font-bold text-7xl">
                    {toKrw(Math.floor(result.expected) * 10000)}
                  </span>
                  이 되었다... 또륵...
                  <br />
                </div>
              </div>
            )}
          </div>
          {/* 무엇을 살 수 있나? */}
          <div
            className="flex w-full justify-center items-center bg-black text-white 
                  font-bold py-10  text-center -mt-1"
          >
            그 돈이면 <br />
            무엇을 살 수 있나?
          </div>
          <div
            className="flex-row w-full justify-center items-center p-8
                      bg-gradient-to-r from-sky-400 via-rose-400 to-lime-400"
            // "
          >
            {" "}
            {result?.goods && result.earned > 0 && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-6">
                {result.goods
                  .filter((item) => item.count >= 1)
                  .map((item, index) => {
                    return (
                      <ResultCard
                        key={`result-${item.name}-${item.subName}`}
                        item={item}
                        onClick={() => openLink(item)}
                      />
                    );
                  })}
              </div>
              // {/* ) : ( */}
            )}
            <div className="flex-row justify-center items-center text-lg bg-white rounded-2xl p-4 w-auto text-black mt-10">
              <div className="flex justify-center items-center text-2xl font-bold">
                공유하기
              </div>
              <div className="flex-row justify-center items-center">
                <div
                  id="btnTwitter"
                  className="flex justify-center items-center mt-2 border-2 border-gray-400 rounded-full p-2 font-bold"
                  onClick={() => shareTwitter()}
                >
                  {/* <div className="link-icon twitter w-8 h-8 mr-2"></div> */}
                  <svg
                    className="w-8 h-8 text-blue-300 fill-current mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
                  </svg>
                  <div className=""> 트위터 공유</div>
                </div>
                <div
                  id="btnKakao"
                  className="flex justify-center items-center mt-2 border-2 border-gray-400 rounded-full p-2 font-bold"
                >
                  <a id="kakao-link-btn" href onClick={shareKakao}>
                    <img
                      className="link-icon w-8 h-8 mr-1"
                      src="https://developers.kakao.com/assets/img/about/logos/kakaolink/kakaolink_btn_medium.png"
                      alt="카카오링크 보내기 버튼"
                    />
                  </a>
                  <div className=""> 카카오 공유</div>
                </div>
                <div
                  id="btnKakao"
                  className="flex justify-center items-center mt-2 border-2 border-gray-400 rounded-full p-2 font-bold"
                >
                  <svg
                    className="w-8 h-8 mr-2 text-blue-600 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                  </svg>
                  <div
                    className="fb-share-button"
                    data-href="https://developers.facebook.com/docs/plugins/"
                    data-layout="button_count"
                    data-size="small"
                  >
                    <a
                      target="_blank"
                      href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fkkeolkkeol.com"
                      className="fb-xfbml-parse-ignore"
                      onClick={() => {
                        gtag("event", "share", {
                          event_platform: "facebook",
                        });
                      }}
                    >
                      페이스북 공유
                    </a>
                  </div>
                </div>
                <div
                  id="btnTelegram"
                  className="flex justify-center items-center mt-2 border-2 border-gray-400 rounded-full p-2 font-bold"
                  onClick={() => shareTelegram()}
                >
                  {/* <div className="link-icon twitter w-8 h-8 mr-2"></div> */}
                  <svg
                    className="w-8 h-8 mr-2 text-gray-400 text-blue-400 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 496 512"
                  >
                    <path d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z"></path>
                  </svg>
                  <div className=""> 텔레그램 공유</div>
                </div>
                <div
                  className="flex justify-center items-center mt-2 border-2 border-gray-400 rounded-full p-2 font-bold"
                  onClick={copy}
                >
                  <img
                    className={`w-8 h-8 mr-1`}
                    src={"img/share-icon.svg"}
                    alt={"share icon"}
                  />{" "}
                  URL 복사하기
                </div>
              </div>
              <input
                type="hidden"
                id="share_url"
                value={shareUrl}
                ref={textInput}
                readOnly
              />
            </div>
            <div
              className="flex justify-center items-center text-2xl bg-black rounded-2xl p-7 w-auto text-white font-bold
            mt-8"
            >
              {" "}
              <a href="#k_input">다시 계산하기</a>
            </div>
          </div>
        </div>
      )}
      <div className="flex-row justify-center items-center bg-gray-100">
        {/* <iframe
          src="https://ads-partners.coupang.com/widgets.html?id=562327&template=carousel&trackingCode=AF3734069&subId=&width=100%&height=140"
          width="100%"
          height="140"
          frameborder="0"
          scrolling="no"
          referrerpolicy="unsafe-url"
        ></iframe> */}
        <a
          href="https://link.coupang.com/a/kbNZz"
          target="_blank"
          referrerPolicy="unsafe-url"
          onClick={() => {
            gtag("event", "click_url", {
              event_item: "쿠팡배너",
            });
          }}
        >
          <img
            className="mx-auto"
            src="https://ads-partners.coupang.com/banners/562305?subId=&traceId=V0-301-879dd1202e5c73b2-I562305&w=728&h=90"
            alt="cupang banner"
          />
        </a>
      </div>
      <div className="flex-row justify-center items-center text-md p-4 w-auto bg-gray-400 text-gray-200">
        {" "}
        <div className="flex text-lg">About us</div>
        <div className="flex mt-4">
          <a href="https://twitter.com/kkeol_kkeol_">
            개발자 껄껄 (프로필 보기)
          </a>
        </div>
        <div className="flex ">귀여운 디자이너(익명)</div>
      </div>
    </div>
  );
}

export default App;
