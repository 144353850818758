function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const getGridCol = (count) => {
  // if (count === 1) {
  // return "grid-cols-1";
  // }
  //  else if (count === 2) {
  return "grid-cols-2";
  // } else if (count === 3) {
  //   return "grid-cols-3";
  // }
  // return "grid-cols-4";
};

const getWidth = (count) => {
  // if (count === 1) {
  // return "w-48";
  // return "w-12";
  // }
  //  else if (count === 2) {
  //   return "w-64"; //64 72 80 96
  // } else if (count === 3) {
  //   return "w-56"; //48 56 64
  // }
  // return "mx-auto";
  return "w-36";
};

const getReadableCount = (count) => {
  if (count > 10000000) {
    return `${numberWithCommas(Math.floor(count / 1000000) * 1000000)} `;
  }
  if (count > 100000) {
    return `${numberWithCommas(Math.floor(count / 10000) * 10000)} `;
  }
  if (count > 10000) {
    return `${numberWithCommas(Math.floor(count / 1000) * 1000)} `;
  }
  if (count > 1000) {
    return `${numberWithCommas(Math.floor(count / 100) * 100)} `;
  }
  if (count > 1000) {
    return numberWithCommas(count);
  }
  return numberWithCommas(count);
};

const ResultCard = ({ item, onClick }) => {
  const viewCount = getReadableCount(item.count);

  const limit = 1;
  return (
    <div
      onClick={onClick}
      className="grid shadow-lg rounded-3xl w-auto p-4 bg-white
     relative overflow-hidden text-center"
    >
      <div className="text-black text-3xl">
        <span className="font-bold">{item.name}</span>
        <span>
          <span className="font-bold text-gray-500"> {item.subName}</span>
        </span>
        <br />
      </div>
      <div className={`grid ${getGridCol(item.count)} gap-3 mt-5`}>
        {item.imgPath &&
          Array.from(Array(item.count > limit ? limit : item.count)).map(
            (_, i) => (
              <div key={`ri-${i}`} className="flex flex-col items-center">
                <div className="relative">
                  <img
                    className={`mx-auto ${getWidth(item.count)} object-cover`}
                    src={item.imgPath}
                    alt={item.name}
                  />
                </div>
              </div>
            )
          )}
        <div
          className={`flex font-bold justify-center items-center text-center align-bottom text-black mt-3 ${
            item.count < 1000 ? "text-3xl" : "text-xl"
          }`}
        >
          {item.count > 1000 && "약 "}
          {viewCount} {item.unit}
        </div>
      </div>
      <div className="text-gray-600 text-sm mt-2">
        {item.unit}당 약 ₩{numberWithCommas(Math.floor(item.value * 10000))}{" "}
        (링크)
      </div>
    </div>
  );
};

export default ResultCard;
